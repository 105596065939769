import React, { Component } from "react";
import {
  Header,
  Image,
  Dropdown,
  Button,
  Form,
  Segment,
  Icon,
  Divider,
  Table,
} from "semantic-ui-react";
import schedule_icon from "../media/Images/schedule_icon.png";
import "../stylesheets/Schedule.css";
import $ from "jquery";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Inject,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
} from "@syncfusion/ej2-react-schedule";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import ModalExampleCloseIcon from "./ModalExampleCloseIcon";
//import {date} from 'react-datetime-picker';
import track from "./track";

class Schedule extends Component {
  constructor(props) {
    super(...arguments);
    /*this.data = extend([], scheduleData, null, true);*/

    this.state = {
      todayDate: new Date(), // Assuming start_date is passed as a prop
      lastSaturday: null,
      nextFriday: null,
      screenChange: false,
      group_classes: "Hide",
      fixed_privates: "Hide",
      privates: "Hide",
      workshops: "Hide",
      fixed_rent: "Hide",
      rent: "Hide",
      fixed_team_reservation: "Hide",
      team_reservation: "Hide",

      data: [],
      all_data: [],
      classes: [],

      room: 0,
      class_description: "",
      level_id: 1,
      duration: 60,
      dance_category_id: 1,
      dance_id: 0,
      class_type: "",
      slot_count_current: 1,

      date1_start: new Date(),
      roomNew1: 1,

      date2_start: new Date(),
      roomNew2: 1,

      slot_count: [
        {
          key: 1,
          text: "Once a week",
          value: 1,
        },
        {
          key: 2,
          text: "Twice a week",
          value: 2,
        },
      ],

      durationOptions: [
        {
          key: 30,
          text: "30 Minutes",
          value: 30,
        },
        {
          key: 60,
          text: "60 Minutes",
          value: 60,
        },
        {
          key: 90,
          text: "90 Minutes",
          value: 90,
        },
        {
          key: 120,
          text: "120 Minutes",
          value: 120,
        },
        {
          key: 150,
          text: "150 Minutes",
          value: 150,
        },
        {
          key: 180,
          text: "180 Minutes",
          value: 180,
        },
        {
          key: 210,
          text: "210 Minutes",
          value: 210,
        },
        {
          key: 240,
          text: "240 Minutes",
          value: 240,
        },
        {
          key: 270,
          text: "270 Minutes",
          value: 270,
        },
        {
          key: 300,
          text: "300 Minutes",
          value: 300,
        },
      ],

      fixed_classes: [],
      onetime_classes: [],

      rooms: [],
      rooms_choice: [
        {
          key: 0,
          value: 0,
          text: "All Rooms",
        },
      ],

      levels: [],
      dance_categories: [],
      dances_options: [],
      dances: [],
      class_types_available: [
        {
          key: "Group Class",
          text: "Group Class",
          value: "Group Class",
        },
        {
          key: "Private Class - Fixed",
          text: "Private Class - Fixed",
          value: "Private Class - Fixed",
        },
        {
          key: "Private Class",
          text: "Private Class",
          value: "Private Class",
        },
        {
          key: "Workshop",
          text: "Workshop",
          value: "Workshop",
        },
      ],
      appointments_types_available: [
        {
          key: "Fixed Appointment",
          text: "Fixed Appointment",
          value: "Fixed Appointment",
        },
        {
          key: "Onetime Appointment",
          text: "Onetime Appointment",
          value: "Onetime Appointment",
        },
      ],
      rent_reserve_available: [
        {
          key: "Rent",
          text: "Rent",
          value: "Rent",
        },
        {
          key: "Team Reservation",
          text: "Team Reservation",
          value: "Team Reservation",
        },
      ],
      appointments_types: "Onetime Appointment",
      rentReserveDateStart: new Date(),
      rentReserveDuration: 60,
      rent_reserve: "Rent",
      rentReserveName: "",
      roomRentReserve: 1,
      roomRentPrice: 0,
      roomRentPriceAfterDiscount: 0,
      roomRentDiscount: 0,
      //handle a class & attendance
      startTimeAttendance: new Date(),
      slot2Attendance: new Date(),

      isFixedAttendance: "",
      scheduleTypeAttendance: "",
      classIdAttendance: 0,
      schedule_id: 0,
      cancelation: "no",
      attendanceId: 0,

      //View Rent & Pay Rest
      isRentedPay: false,
      rentDiscountPay: 0,
      rentNamePay: "",
      rentPaidPay: 0,
      rentRemainingPay: 0,
      rentValuePay: 0,
      rentValueAfterDiscountPay: 0,
      paymentMethodsAvailable: [
        {
          key: "Cash",
          text: "Cash",
          value: "Cash",
        },
        {
          key: "Credit Card",
          text: "Credit Card",
          value: "Credit Card",
        },
      ],
      rentNewPay: 0,
      rentNewMethod: "Cash",
    };
  }

  componentDidMount() {
    document.getElementById("add_class").hidden = true;
    document.getElementById("edit_class").hidden = true;
    document.getElementById("rent_fields").hidden = true;
    document.getElementById("reserve_fields").hidden = true;
    document.getElementById("rent_reserve").hidden = true;
    document.getElementById("slot").hidden = true;
    document.getElementById("date2").hidden = true;
    document.getElementById("room_extra").hidden = true;

    document.getElementById("date2_reschedule").hidden = true;
    document.getElementById("room_extra_reschedule").hidden = true;

    document.getElementById("class_type_buttons").hidden = true;

    document.getElementById("class_attendance_info_admin").hidden = true;
    document.getElementById("class_info").hidden = true;
    document.getElementById("attendance_info1").hidden = true;
    document.getElementById("class_cancel_once").hidden = true;
    document.getElementById("class_cancel_once_restore").hidden = true;
    document.getElementById("class_remove").hidden = true;
    document.getElementById("pay_rent").hidden = true;
    document.getElementById("view_rent").hidden = true;

    document.getElementById("class_deactivate").hidden = true;
    document.getElementById("class_reschedule").hidden = true;

    document.getElementById("rent_info_pay").hidden = true;
    document.getElementById("rent_info_view").hidden = true;

    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState(
            {
              rooms: result.rooms,
              rooms_choice: this.state.rooms_choice.concat(result.rooms),
            },
            async () => {
              this.showLegend();
            }
          );
          return;
        },
        error: (error) => {
          alert("Unable to load rooms. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/levels`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ levels: result.levels });
          return;
        },
        error: (error) => {
          alert("Unable to load levels. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dance_categories: result.dance_categories });
          return;
        },
        error: (error) => {
          alert(
            "Unable to load dance categories. Please try your request again"
          );
          return;
        },
      });

      $.ajax({
        url: `/api/dances`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dances: result.dances });
          return;
        },
        error: (error) => {
          alert("Unable to load dances. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/dances/${this.state.dance_category_id}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dances_options: result.dances });
          return;
        },
        error: (error) => {
          alert("Unable to load dances. Please try your request again");
          return;
        },
      });

      const thirdFunction = async () => {
        await this.calculateDates();

        $.ajax({
          url: `/api/schedules/${this.state.room}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            last_saturday: this.state.lastSaturday,
            next_friday: this.state.nextFriday,
          }),
          success: (result) => {
            this.setState({ all_data: result.data }, async () => {
              this.setState({ data: result.data });
            });

            return;
          },
          error: (error) => {
            //alert('Unable to load the schedule of the chosen room. Please try your request again')
            return;
          },
        });
      };
      thirdFunction();
    };
    secondFunction();
  }

  async calculateDates() {
    const { todayDate } = this.state;

    // Get the previous Saturday date
    const lastSaturday = new Date(todayDate);
    lastSaturday.setDate(
      lastSaturday.getDate() - ((lastSaturday.getDay() + 1) % 7)
    );
    lastSaturday.setHours(0, 0, 0, 0);

    // Get the coming Friday date
    const nextFriday = new Date(todayDate);
    nextFriday.setDate(
      nextFriday.getDate() + ((5 - nextFriday.getDay() + 7) % 7)
    );
    nextFriday.setHours(0, 0, 0, 0);

    this.setState({ lastSaturday: lastSaturday, nextFriday: nextFriday });
  }

  async checkCalculateDates(date_new) {
    // Get the previous Saturday date
    const lastSaturday = new Date(date_new);
    lastSaturday.setDate(
      lastSaturday.getDate() - ((lastSaturday.getDay() + 1) % 7)
    );
    lastSaturday.setHours(0, 0, 0, 0);

    // Get the coming Friday date
    const nextFriday = new Date(date_new);
    nextFriday.setDate(
      nextFriday.getDate() + ((5 - nextFriday.getDay() + 7) % 7)
    );
    nextFriday.setHours(0, 0, 0, 0);

    if (
      lastSaturday.getTime() !== this.state.lastSaturday?.getTime() ||
      lastSaturday.getTime() !== this.state.lastSaturday?.getTime()
    ) {
      this.setState({
        nextFriday,
        lastSaturday,
        screenChange: true,
      });
    }
  }

  handleChange = (event, { key, value }) => {
    this.setState({ [key]: value });
  };

  handleChangeLevel = (event, { value }) => {
    this.setState({ level_id: value });
  };

  chooseRoom = (event, { value }) => {
    //let previousRoom = this.state.room;
    this.setState({ room: value }, async () => {
      if (this.state.room === 0) {
        this.showLegend();
        document.getElementById("class_type_buttons").hidden = true;
        document.getElementById("calendar_admin_schedule").scrollIntoView();
      } else {
        this.hideLegend();
        document.getElementById("class_type_buttons").hidden = false;
        document.getElementById("calendar_admin_schedule").scrollIntoView();
      }
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/schedules/${this.state.room}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            last_saturday: this.state.lastSaturday,
            next_friday: this.state.nextFriday,
          }),
          success: (result) => {
            this.setState({
              all_data: result.data,
              data: result.data,
              group_classes: "Hide",
              fixed_privates: "Hide",
              privates: "Hide",
              workshops: "Hide",
              fixed_rent: "Hide",
              rent: "Hide",
              fixed_team_reservation: "Hide",
              team_reservation: "Hide",
            });
            return;
          },
          error: (error) => {
            //alert('Unable to load the schedule of the chosen room. Please try your request again');
            this.setState({
              all_data: [],
              data: [],
              group_classes: "Hide",
              fixed_privates: "Hide",
              privates: "Hide",
              workshops: "Hide",
              fixed_rent: "Hide",
              rent: "Hide",
              fixed_team_reservation: "Hide",
              team_reservation: "Hide",
            });
            return;
          },
        });
      };
      secondFunction();
    });
  };

  chooseRoomRentReserve = (event, { value }) => {
    for (let i = 0; i < this.state.rooms.length; i++) {
      if (this.state.rooms[i].key === value) {
        this.setState({
          roomRentReserve: value,
          roomRentPrice: this.state.rooms[i].rent_value,
          roomRentPriceAfterDiscount:
            this.state.rooms[i].rent_value * (1 - this.state.roomRentDiscount),
        });
        this.setState({});
        break;
      }
    }
    this.setState({});
  };

  chooseRoomNew1 = (event, { value }) => {
    this.setState({ roomNew1: value });
  };

  chooseRoomNew2 = (event, { value }) => {
    this.setState({ roomNew2: value });
  };

  handleChangeCategory = (event, { value }) => {
    this.setState({ dance_category_id: value }, async () => {
      this.setState(
        {
          dances_options: this.state.dances[this.state.dance_category_id],
        },
        async () => {
          //console.log(this.state.dance_category_id)
        }
      );
    });
  };

  handleChangeDance = (event, { value }) => {
    this.setState({ dance_id: value }, async () => {
      //console.log(this.state.dance_id)
    });
  };

  handleAppointments_types = (event, { value }) => {
    this.setState({ appointments_types: value }, async () => {});
  };

  handleRentReserve = (event, { value }) => {
    this.setState({ rent_reserve: value }, async () => {
      if (this.state.rent_reserve === "Rent") {
        document.getElementById("rent_fields").hidden = false;
        document.getElementById("reserve_fields").hidden = true;
      } else {
        document.getElementById("rent_fields").hidden = true;
        document.getElementById("reserve_fields").hidden = false;
      }
    });
  };

  handleRoomRentDiscount = (event) => {
    this.setState({
      roomRentDiscount: event.target.value / 100,
      roomRentPriceAfterDiscount:
        this.state.roomRentPrice * (1 - event.target.value / 100),
    });
  };

  handleRentReserveName = (event) => {
    this.setState({ rentReserveName: event.target.value }, async () => {});
  };

  handleRentReserveDuration = (event, { value }) => {
    this.setState({ rentReserveDuration: value });
  };

  handleChangeClassType = (event, { value }) => {
    this.setState({ class_type: value }, async () => {
      if (
        this.state.class_type === "Group Class" ||
        this.state.class_type === "Private Class - Fixed"
      ) {
        this.setState({ slot_count_current: 1 }, async () => {
          document.getElementById("date2").hidden = true;
          document.getElementById("room_extra").hidden = true;
          document.getElementById("slot").hidden = false;
        });
      } else {
        this.setState({ slot_count_current: 1 }, async () => {
          document.getElementById("date2").hidden = true;
          document.getElementById("room_extra").hidden = true;
          document.getElementById("slot").hidden = true;
        });
      }
    });
  };

  handleChangeDate1Start = (event) => {
    this.setState({
      date1_start: event.target.value,
      //        events: this.logs.slice()
    });
  };

  handleChangeDate2Start = (event) => {
    this.setState({
      date2_start: event.target.value,
      //        events: this.logs.slice()
    });
  };

  add_class = () => {
    //console.log('class info:')
    //console.log("class description = ",this.state.class_description);
    //console.log("level_id = ",this.state.level_id);
    //console.log("room = ",this.state.room);
    //console.log("dance_category_id = ",this.state.dance_category_id);
    //console.log("dance_id = ",this.state.dance_id);
    //console.log("class_type = ",this.state.class_type);
    //console.log("slot_count_current = ",this.state.slot_count_current);
    //console.log("date1_start = ",this.state.date1_start);
    //console.log("date2_start = ",this.state.date2_start);
  };
  handleRentReserveDateStart = (event) => {
    this.setState({
      rentReserveDateStart: event.target.value,
      //        events: this.logs.slice()
    });
  };

  addRentReserve = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/schedules`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: this.state.rent_reserve,
          appointments_types: this.state.appointments_types,
          rent_reserve: this.state.rent_reserve,
          rent_reserve_name: this.state.rentReserveName,
          room_rent_reserve: this.state.roomRentReserve,
          rent_reserve_date_start: this.state.rentReserveDateStart,
          rent_reserve_duration: this.state.rentReserveDuration,
          room_rent_price: this.state.roomRentPrice,
          room_rent_price_after_discount: this.state.roomRentPriceAfterDiscount,
          room_rent_discount: this.state.roomRentDiscount,

          description: "",
          level_id: 0,
          duration: 0,
          dance_cat_id: 0,
          dance_id: 0,
          class_type: "",
          slot_count_current: 1,
          date1_start: this.state.date1_start,
          room1: 0,
          date2_start: this.state.date2_start,
          room2: 0,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `Added ${this.state.rent_reserve}, time = ${this.state.rentReserveDateStart}, (${this.state.appointments_types})`,
            `${this.state.rent_reserve}`,
            "Add"
          );

          document.getElementById("rent_reserve").hidden = true;
          $.ajax({
            url: `/api/schedules/${this.state.room}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {
              //Include the bearer token in header
              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + sessionStorage.getItem("access_token")
              );
            },
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({
              last_saturday: this.state.lastSaturday,
              next_friday: this.state.nextFriday,
            }),
            success: (result) => {
              this.setState({
                all_data: result.data,
                data: result.data,
                group_classes: "Hide",
                fixed_privates: "Hide",
                privates: "Hide",
                workshops: "Hide",
                fixed_rent: "Hide",
                rent: "Hide",
                fixed_team_reservation: "Hide",
                team_reservation: "Hide",
              });
              return;
            },
            error: (error) => {
              //alert('Unable to load the schedule of the chosen room. Please try your request again')
              return;
            },
          });
        },
        error: (error) => {
          alert("Unable to create a class. Please try again later");
          return;
        },
      });
    };
    secondFunction();
  };

  addClass = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/schedules`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: "new_class",
          appointments_types: this.state.appointments_types,
          rent_reserve: this.state.rent_reserve,
          rent_reserve_name: this.state.rentReserveName,
          room_rent_reserve: this.state.roomRentReserve,
          rent_reserve_date_start: this.state.rentReserveDateStart,
          rent_reserve_duration: this.state.rentReserveDuration,
          room_rent_price: this.state.roomRentPrice,
          room_rent_price_after_discount: this.state.roomRentPriceAfterDiscount,
          room_rent_discount: this.state.roomRentDiscount,

          description: this.state.class_description,
          level_id: this.state.level_id,
          duration: this.state.duration,
          dance_cat_id: this.state.dance_category_id,
          dance_id: this.state.dance_id,
          class_type: this.state.class_type,
          slot_count_current: this.state.slot_count_current,
          date1_start: this.state.date1_start,
          room1: this.state.roomNew1,
          date2_start: this.state.date2_start,
          room2: this.state.roomNew2,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `Added class at time = ${this.state.date1_start}${
              this.state.slot_count_current === 2
                ? ` and another slot at ${this.state.date2_start}`
                : ``
            }, (${this.state.appointments_types})`,
            `Class`,
            "Add"
          );

          document.getElementById("add_class").hidden = true;
          $.ajax({
            url: `/api/schedules/${this.state.room}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {
              //Include the bearer token in header
              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + sessionStorage.getItem("access_token")
              );
            },
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({
              last_saturday: this.state.lastSaturday,
              next_friday: this.state.nextFriday,
            }),
            success: (result) => {
              this.setState({
                all_data: result.data,
                data: result.data,
                group_classes: "Hide",
                fixed_privates: "Hide",
                privates: "Hide",
                workshops: "Hide",
                fixed_rent: "Hide",
                rent: "Hide",
                fixed_team_reservation: "Hide",
                team_reservation: "Hide",
              });
              return;
            },
            error: (error) => {
              //alert('Unable to load the schedule of the chosen room. Please try your request again')
              return;
            },
          });
        },
        error: (error) => {
          alert("Unable to create a class. Please try again later");
          return;
        },
      });
    };
    secondFunction();
  };

  class_reschedule_button = (event) => {
    event.preventDefault();
    //console.log("room_new1 = ", this.state.roomNew1)

    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/reschedules`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          schedule_id: this.state.schedule_id,
          ////
          start_time_attendance: this.state.startTimeAttendance,
          slot_count_current: this.state.slot_count_current,
          schedule_type: this.state.scheduleTypeAttendance,

          duration: this.state.duration,
          date1_start: this.state.date1_start,
          date2_start: this.state.date2_start,
          room_new1: this.state.roomNew1,
          room_new2: this.state.roomNew2,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `Rescheduled ${this.state.scheduleTypeAttendance} at time = ${
              this.state.date1_start
            }${
              this.state.slot_count_current === 2
                ? ` and another slot at ${this.state.date2_start}`
                : ``
            }`,
            `${this.state.scheduleTypeAttendance}`,
            "Reschedule"
          );

          window.location.reload();
        },
        error: (error) => {
          alert(
            "Unable to reschedule the Fixed Schedule. Please try again later"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  groups_button = () => {
    if (this.state.group_classes === "Hide") {
      this.setState({ group_classes: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "group" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ group_classes: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "group" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  fixed_privates_button = () => {
    if (this.state.fixed_privates === "Hide") {
      this.setState({ fixed_privates: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "private" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_privates: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "private" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  privates_button = () => {
    if (this.state.privates === "Hide") {
      this.setState({ privates: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "private" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ privates: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "private" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  workshops_button = () => {
    if (this.state.workshops === "Hide") {
      this.setState({ workshops: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "workshop" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ workshops: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "workshop" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  //Rent & reserve buttons
  fixed_rent_button = () => {
    if (this.state.fixed_rent === "Hide") {
      this.setState({ fixed_rent: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "Rent" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_rent: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "Rent" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  rent_button = () => {
    if (this.state.rent === "Hide") {
      this.setState({ rent: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "Rent" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ rent: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "Rent" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  fixed_team_reservation_button = () => {
    if (this.state.fixed_team_reservation === "Hide") {
      this.setState({ fixed_team_reservation: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (
              d.schedule_type === "Team Reservation" &&
              d.is_fixed === "fixed"
            ) {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_team_reservation: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (
                d.schedule_type === "Team Reservation" &&
                d.is_fixed === "fixed"
              ) {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  team_reservation_button = () => {
    if (this.state.team_reservation === "Hide") {
      this.setState({ team_reservation: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (
              d.schedule_type === "Team Reservation" &&
              d.is_fixed === "onetime"
            ) {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ team_reservation: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (
                d.schedule_type === "Team Reservation" &&
                d.is_fixed === "onetime"
              ) {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  new_class = () => {
    if (document.getElementById("add_class").hidden) {
      document.getElementById("add_class").hidden = false;
      document.getElementById("edit_class").hidden = true;
      document.getElementById("rent_reserve").hidden = true;
      document.getElementById("add_class").scrollIntoView();
    } else {
      document.getElementById("add_class").hidden = true;
      document.getElementById("schedule_actions").scrollIntoView();
    }
  };

  reschedule_class = () => {
    if (document.getElementById("edit_class").hidden) {
      document.getElementById("edit_class").hidden = false;
      document.getElementById("add_class").hidden = true;
      document.getElementById("rent_reserve").hidden = true;
      document.getElementById("edit_class").scrollIntoView();
    } else {
      document.getElementById("edit_class").hidden = true;
      document.getElementById("schedule_actions").scrollIntoView();
    }
  };

  rent_reserve = () => {
    if (document.getElementById("rent_reserve").hidden) {
      document.getElementById("rent_reserve").hidden = false;
      document.getElementById("edit_class").hidden = true;
      document.getElementById("add_class").hidden = true;
      document.getElementById("rent_reserve").scrollIntoView();
    } else {
      document.getElementById("rent_reserve").hidden = true;
      document.getElementById("schedule_actions").scrollIntoView();
    }
  };

  handleClassName = (event) => {
    this.setState({ class_description: event.target.value });
  };

  handleDuration = (event, { value }) => {
    this.setState({ duration: value });
  };

  slot_count_prepare = (temp_value) => {
    this.setState({ slot_count_current: temp_value }, async () => {
      if (this.state.slot_count_current === 2) {
        document.getElementById("date2").hidden = false;
        document.getElementById("room_extra").hidden = false;
        document.getElementById("date2_reschedule").hidden = false;
        document.getElementById("room_extra_reschedule").hidden = false;
      } else {
        document.getElementById("date2").hidden = true;
        document.getElementById("room_extra").hidden = true;
        document.getElementById("date2_reschedule").hidden = true;
        document.getElementById("room_extra_reschedule").hidden = true;
      }
    });
  };

  slot_count_set = (event, { value }) => {
    this.setState({ slot_count_current: value }, async () => {
      if (this.state.slot_count_current === 2) {
        document.getElementById("date2").hidden = false;
        document.getElementById("room_extra").hidden = false;
        document.getElementById("date2_reschedule").hidden = false;
        document.getElementById("room_extra_reschedule").hidden = false;
      } else {
        document.getElementById("date2").hidden = true;
        document.getElementById("room_extra").hidden = true;
        document.getElementById("date2_reschedule").hidden = true;
        document.getElementById("room_extra_reschedule").hidden = true;
      }
    });
  };

  //  eventTemplate(props:{[key: string]: Object}):JSX.Element{
  eventTemplate(props) {
    //console.log("hi")
    if (props.is_all_rooms) {
      let r_id = props.room_id;
      if (props.room_id > 6) {
        r_id = props.room_id - 6 * Math.floor(props.room_id / 6);
      }
      if (props.cancelation === "yes") {
        // start of canceled
        if (props.schedule_type === "group") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "workshop") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }

        // end of canceled
      } else if (props.is_fixed === "fixed") {
        if (props.schedule_type === "group") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      } else if (props.is_fixed === "onetime") {
        if (props.schedule_type === "workshop") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      }
    } else {
      //per room
      if (props.cancelation === "yes") {
        // start of canceled
        if (props.schedule_type === "group") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "workshop") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
        // end of canceled
      } else if (props.is_fixed === "fixed") {
        if (props.schedule_type === "group") {
          return (
            <div id="group">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="fixed_private">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="fixed_rent">
              Fixed {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="fixed_reserve">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      } else if (props.is_fixed === "onetime") {
        if (props.schedule_type === "workshop") {
          return (
            <div id="workshop">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="private">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="rent">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="reserve">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      }
    }
  }

  showLegend = () => {
    //console.log('Here is why',this.state.rooms.length)
    for (let i = 1; i <= 6; i++) {
      if (i <= this.state.rooms.length) {
        document.getElementById(
          "room_legend_admin_schedule_" + i
        ).style.display = "inline";
        //console.log('Here is id',"room_legend_admin_schedule_"+i)
      } else {
        document.getElementById(
          "room_legend_admin_schedule_" + i
        ).style.display = "none";
        //console.log('Here is id',"room_legend_admin_schedule_"+i)
      }
    }
  };

  hideLegend = () => {
    for (let i = 1; i <= 6; i++) {
      document.getElementById("room_legend_admin_schedule_" + i).style.display =
        "none";
    }
  };

  onPopupOpen(args) {
    args.cancel = true;
    const thirdFunction = async () => {
      await this.checkCalculateDates(args.data.StartTime);
      if (this.state.screenChange) {
        $.ajax({
          url: `/api/schedules/${this.state.room}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            last_saturday: this.state.lastSaturday,
            next_friday: this.state.nextFriday,
          }),
          success: (result) => {
            this.setState(
              { screenChange: false, all_data: result.data },
              async () => {
                this.setState({ data: result.data });
              }
            );

            return;
          },
          error: (error) => {
            //alert('Unable to load the schedule of the chosen room. Please try your request again')
            return;
          },
        });
      }
    };
    thirdFunction();

    if (typeof args.data.class_id !== "undefined") {
      document.getElementById("add_class").hidden = true;
      document.getElementById("edit_class").hidden = true;
      document.getElementById("rent_fields").hidden = true;
      document.getElementById("reserve_fields").hidden = true;
      document.getElementById("rent_reserve").hidden = true;
      document.getElementById("slot").hidden = true;
      document.getElementById("date2").hidden = true;
      document.getElementById("room_extra").hidden = true;

      document.getElementById("date2_reschedule").hidden = true;
      document.getElementById("room_extra_reschedule").hidden = true;

      this.slot_count_prepare(args.data.slots_count);

      this.setState(
        {
          classIdAttendance: args.data.class_id,
          startTimeAttendance: new Date(args.data.StartTime),
          slot2Attendance: new Date(args.data.slot_2),
          isFixedAttendance: args.data.is_fixed,

          scheduleTypeAttendance: args.data.schedule_type,

          schedule_id: args.data.id,
          cancelation: args.data.cancelation,

          duration: args.data.duration,
          date1_start: new Date(args.data.StartTime),
          date2_start: new Date(args.data.slot_2),
          roomNew1: args.data.room1,
          roomNew2: args.data.room2,
        },
        async () => {
          if (
            this.state.scheduleTypeAttendance === "Rent" ||
            this.state.scheduleTypeAttendance === "Team Reservation"
          ) {
            document.getElementById("reschedule_slots1").hidden = true;

            document.getElementById("date2").hidden = true;
            document.getElementById("room_extra").hidden = true;
            document.getElementById("date2_reschedule").hidden = true;
            document.getElementById("room_extra_reschedule").hidden = true;
            this.setState({ slot_count_current: 1 });
          } else {
            document.getElementById("reschedule_slots1").hidden = false;
          }
          const secondFunction = async () => {
            const result = await this.props.refreshToken();
            // do something else here after firstFunction completes
            $.ajax({
              url: `/api/buttons`, //TODO: update request URL
              type: "POST",
              beforeSend: function (xhr) {
                //Include the bearer token in header
                xhr.setRequestHeader(
                  "Authorization",
                  "Bearer " + sessionStorage.getItem("access_token")
                );
              },
              dataType: "json",
              contentType: "application/json",
              data: JSON.stringify({
                class_id: this.state.classIdAttendance,
                date_start: this.state.startTimeAttendance,
                is_fixed: this.state.isFixedAttendance,
                schedule_type: this.state.scheduleTypeAttendance,
                schedule_id: this.state.schedule_id,
                cancelation: this.state.cancelation,
              }),
              xhrFields: {
                withCredentials: true,
              },
              crossDomain: true,
              success: (result) => {
                this.setState({ attendanceId: result.attendance_id });
                if (this.state.cancelation === "no") {
                  document.getElementById(
                    "class_attendance_info_admin"
                  ).hidden = false;
                  document
                    .getElementById("class_attendance_info_admin")
                    .scrollIntoView();

                  if (result.class_info === "show") {
                    document.getElementById("class_info").hidden = false;
                  } else {
                    document.getElementById("class_info").hidden = true;
                  }

                  if (result.attendance_info === "show") {
                    document.getElementById("attendance_info1").hidden = false;
                  } else {
                    document.getElementById("attendance_info1").hidden = true;
                  }

                  if (result.pay_rent === "show") {
                    document.getElementById("pay_rent").hidden = false;
                  } else {
                    document.getElementById("pay_rent").hidden = true;
                  }

                  if (result.view_rent === "show") {
                    document.getElementById("view_rent").hidden = false;
                  } else {
                    document.getElementById("view_rent").hidden = true;
                  }

                  if (result.cancel_once === "show") {
                    document.getElementById("class_cancel_once").hidden = false;
                  } else {
                    document.getElementById("class_cancel_once").hidden = true;
                  }

                  if (result.cancel_once_restore === "show") {
                    document.getElementById(
                      "class_cancel_once_restore"
                    ).hidden = false;
                  } else {
                    document.getElementById(
                      "class_cancel_once_restore"
                    ).hidden = true;
                  }

                  if (result.remove === "show") {
                    document.getElementById("class_remove").hidden = false;
                  } else {
                    document.getElementById("class_remove").hidden = true;
                  }

                  if (result.deactivate === "show") {
                    document.getElementById("class_deactivate").hidden = false;
                  } else {
                    document.getElementById("class_deactivate").hidden = true;
                  }

                  if (result.reschedule === "show") {
                    document.getElementById("class_reschedule").hidden = false;
                  } else {
                    document.getElementById("class_reschedule").hidden = true;
                  }
                } else if (this.state.cancelation === "yes") {
                  document.getElementById(
                    "class_attendance_info_admin"
                  ).hidden = true;
                  document
                    .getElementById("calendar_admin_schedule")
                    .scrollIntoView();
                  document.getElementById("class_info").hidden = true;
                  document.getElementById("attendance_info1").hidden = true;
                  document.getElementById("pay_rent").hidden = true;
                  document.getElementById("view_rent").hidden = true;
                  document.getElementById("class_cancel_once").hidden = true;
                  document.getElementById(
                    "class_cancel_once_restore"
                  ).hidden = true;
                  document.getElementById("class_remove").hidden = true;
                  document.getElementById("class_deactivate").hidden = true;
                  document.getElementById("class_reschedule").hidden = true;
                }
              },
              error: (error) => {
                alert(
                  "Unable to configure the related buttons. Please try again later"
                );
                return;
              },
            });
          };
          secondFunction();
        }
      );
    } else {
      document.getElementById("class_attendance_info_admin").hidden = true;
      document.getElementById("calendar_admin_schedule").scrollIntoView();
      document.getElementById("class_info").hidden = true;
      document.getElementById("attendance_info1").hidden = true;
      document.getElementById("class_cancel_once").hidden = true;
      document.getElementById("class_cancel_once_restore").hidden = true;
      document.getElementById("class_remove").hidden = true;
      document.getElementById("pay_rent").hidden = true;
      document.getElementById("view_rent").hidden = true;
      document.getElementById("class_deactivate").hidden = true;
      document.getElementById("class_reschedule").hidden = true;

      document.getElementById("rent_info_view").hidden = true;
      document.getElementById("rent_info_pay").hidden = true;
    }
  }

  class_cancel_once_button = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: "cancel_once",
          class_id: this.state.classIdAttendance,
          date_start: this.state.startTimeAttendance,
          is_fixed: this.state.isFixedAttendance,
          schedule_type: this.state.scheduleTypeAttendance,
          schedule_id: this.state.schedule_id,
          attendance_id: this.state.attendanceId,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `canceled class at time = ${this.state.startTimeAttendance}, (${this.state.scheduleTypeAttendance})`,
            `${this.state.scheduleTypeAttendance}`,
            "Cancelation"
          );

          window.location.reload();
        },
        error: (error) => {
          alert(
            "Unable to create/Edit the attendance of the class. Please try again later"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  class_cancel_once_restore_button = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: "restore_canceled_once",
          class_id: this.state.classIdAttendance,
          date_start: this.state.startTimeAttendance,
          is_fixed: this.state.isFixedAttendance,
          schedule_type: this.state.scheduleTypeAttendance,
          schedule_id: this.state.schedule_id,
          attendance_id: this.state.attendanceId,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `Restored class at time = ${this.state.startTimeAttendance}, (${this.state.scheduleTypeAttendance})`,
            `${this.state.scheduleTypeAttendance}`,
            "Restoration"
          );

          window.location.reload();
        },
        error: (error) => {
          alert(
            "Unable to create/Edit the attendance of the class. Please try again later"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  class_remove_button = () => {
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: "remove",
          class_id: this.state.classIdAttendance,
          date_start: this.state.startTimeAttendance,
          is_fixed: this.state.isFixedAttendance,
          schedule_type: this.state.scheduleTypeAttendance,
          schedule_id: this.state.schedule_id,
          attendance_id: this.state.attendanceId,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `removed class at time = ${this.state.startTimeAttendance}, (${this.state.scheduleTypeAttendance})`,
            `${this.state.scheduleTypeAttendance}`,
            "Removal"
          );

          window.location.reload();
        },
        error: (error) => {
          alert(
            "Unable to completely cancel the Class. Please try again later"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  class_deactivate_button = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/attendances`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          post_type: "deactivate",
          class_id: this.state.classIdAttendance,
          date_start: this.state.startTimeAttendance,
          is_fixed: this.state.isFixedAttendance,
          schedule_type: this.state.scheduleTypeAttendance,
          schedule_id: this.state.schedule_id,
          attendance_id: this.state.attendanceId,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `deactivated class at time = ${this.state.startTimeAttendance}, (${this.state.scheduleTypeAttendance})`,
            `${this.state.scheduleTypeAttendance}`,
            "Deactivation"
          );

          window.location.reload();
        },
        error: (error) => {
          alert(
            "Unable to deactivate the Fixed Schedule. Please try again later"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  view_rent_button = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rents/${this.state.attendanceId}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({
            isRentedPay: result.rent.is_rented,
            rentDiscountPay: result.rent.rent_discount,
            rentNamePay: result.rent.rent_name,
            rentPaidPay: result.rent.rent_paid,
            rentRemainingPay: result.rent.rent_remaining,
            rentValuePay: result.rent.rent_value,
            rentValueAfterDiscountPay: result.rent.rent_value_after_discount,
          });
          document.getElementById("rent_info_view").hidden = false;
          document.getElementById("rent_info_view").scrollIntoView();
          return;
        },
        error: (error) => {
          alert("Unable to load rent info. Please try your request again");
          return;
        },
      });
    };
    secondFunction();
  };

  pay_rent_button = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rents/${this.state.attendanceId}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({
            isRentedPay: result.rent.is_rented,
            rentDiscountPay: result.rent.rent_discount,
            rentNamePay: result.rent.rent_name,
            rentPaidPay: result.rent.rent_paid,
            rentRemainingPay: result.rent.rent_remaining,
            rentValuePay: result.rent.rent_value,
            rentValueAfterDiscountPay: result.rent.rent_value_after_discount,
          });
          document.getElementById("rent_info_pay").hidden = false;
          document.getElementById("rent_info_pay").scrollIntoView();
          return;
        },
        error: (error) => {
          alert("Unable to load rent info. Please try your request again");
          return;
        },
      });
    };
    secondFunction();
  };

  handleAmountRentPayment = (event) => {
    this.setState({ rentNewPay: event.target.value });
  };

  handleRentNewMethod = (event, { value }) => {
    this.setState({ rentNewMethod: value });
  };

  submitHideRent = (event) => {
    event.preventDefault();
    document.getElementById("rent_info_view").hidden = true;
    document.getElementById("calendar_admin_schedule").scrollIntoView();
  };

  submitPayRent = (event) => {
    event.preventDefault();
    if (Number(this.state.rentNewPay) > Number(this.state.rentRemainingPay)) {
      alert("Amount added is higher than the remaining amount");
    } else {
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/rents/${this.state.attendanceId}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            amount: Number(this.state.rentNewPay),
            method: this.state.rentNewMethod,
          }),
          xhrFields: {
            withCredentials: true,
          },
          crossDomain: true,
          success: (result) => {
            document.getElementById(
              "class_attendance_info_admin"
            ).hidden = true;
            document.getElementById("calendar_admin_schedule").scrollIntoView();
            document.getElementById("class_info").hidden = true;
            document.getElementById("attendance_info1").hidden = true;
            document.getElementById("class_cancel_once").hidden = true;
            document.getElementById("class_cancel_once_restore").hidden = true;
            document.getElementById("class_remove").hidden = true;
            document.getElementById("pay_rent").hidden = true;
            document.getElementById("view_rent").hidden = true;
            document.getElementById("class_deactivate").hidden = true;
            document.getElementById("class_reschedule").hidden = true;

            document.getElementById("rent_info_view").hidden = true;
            document.getElementById("rent_info_pay").hidden = true;
            track(
              `received rent payment of amount ${Number(
                this.state.rentNewPay
              )} in ${this.state.rentNewMethod}`,
              `Rent`,
              "Payment Received"
            );

            $.ajax({
              url: `/api/rents/${this.state.attendanceId}`, //TODO: update request URL
              type: "GET",
              beforeSend: function (xhr) {
                //Include the bearer token in header
                xhr.setRequestHeader(
                  "Authorization",
                  "Bearer " + sessionStorage.getItem("access_token")
                );
              },
              success: (result) => {
                this.setState({
                  isRentedPay: result.rent.is_rented,
                  rentDiscountPay: result.rent.rent_discount,
                  rentNamePay: result.rent.rent_name,
                  rentPaidPay: result.rent.rent_paid,
                  rentRemainingPay: result.rent.rent_remaining,
                  rentValuePay: result.rent.rent_value,
                  rentValueAfterDiscountPay:
                    result.rent.rent_value_after_discount,
                });
                document.getElementById("rent_info_pay").hidden = true;
                document
                  .getElementById("calendar_admin_schedule")
                  .scrollIntoView();
                return;
              },
              error: (error) => {
                alert(
                  "Unable to load rent info. Please try your request again"
                );
                return;
              },
            });
          },
          error: (error) => {
            alert("Unable to submit the rent payment. Please try again later");
            return;
          },
        });
      };
      secondFunction();
    }
  };

  render() {
    return (
      <>
        <div id="headerPartAdminSchedule">
          <Header
            id="scheduleHeaderAdminSchedule"
            as="h2"
            block={true}
            value={this.state.room}
          >
            <Image size="large" circular src={schedule_icon} /> Schedule
          </Header>
        </div>
        <Divider horizontal />

        <ScheduleComponent
          id="calendar_admin_schedule"
          width="100%"
          showHeaderBar={true}
          height="550px"
          currentView="Week"
          firstDayOfWeek={6}
          startHour="12:00"
          endHour="00:00"
          eventSettings={{
            dataSource: this.state.data,
            template: this.eventTemplate.bind(this),
          }}
          // timezone="Etc/GMT-2"
          popupOpen={this.onPopupOpen.bind(this)}
        >
          <ViewsDirective id="here">
            <ViewDirective
              option="Week"
              timeScale={{ enable: true, slotCount: 4 }}
              allowVirtualScrolling={false}
              headerRows="hour"
            />
          </ViewsDirective>
          <Inject services={[Day, Week]} />
        </ScheduleComponent>

        <Divider horizontal />

        <div id="cont_block_admin_schedule">
          <div id="legends_admin_schedule">
            <div id="room_legend_admin_schedule_1">
              <Icon id="legend_1_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 1
                ? this.state.rooms[0].text
                : "Room 1"}
            </div>
            <div id="room_legend_admin_schedule_2">
              <Icon id="legend_2_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 2
                ? this.state.rooms[1].text
                : "Room 2"}
            </div>
            <div id="room_legend_admin_schedule_3">
              <Icon id="legend_3_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 3
                ? this.state.rooms[2].text
                : "Room 3"}
            </div>
            <div id="room_legend_admin_schedule_4">
              <Icon id="legend_4_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 4
                ? this.state.rooms[3].text
                : "Room 4"}
            </div>
            <div id="room_legend_admin_schedule_5">
              <Icon id="legend_5_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 5
                ? this.state.rooms[4].text
                : "Room 5"}
            </div>
            <div id="room_legend_admin_schedule_6">
              <Icon id="legend_6_admin_schedule" name="circle" />{" "}
              {this.state.rooms.length >= 6
                ? this.state.rooms[5].text
                : "Room 6"}
            </div>
          </div>

          <Divider horizontal />

          <div id="class_attendance_info_admin">
            <div className="itemScheduleActionsAdminSchedule" id="class_info">
              <button
                id="class_attendance_info_admin_button"
                onClick={() => {
                  this.props.history.push(
                    `/classes/${this.state.classIdAttendance}`
                  );
                }}
              >
                {" "}
                Class Info{" "}
              </button>
            </div>

            <div
              className="itemScheduleActionsAdminSchedule"
              id="attendance_info1"
            >
              <button
                id="class_attendance_info_admin_button"
                onClick={() => {
                  this.props.history.push(
                    `/attendances/${this.state.attendanceId}`
                  );
                }}
              >
                {" "}
                Attendance{" "}
              </button>
            </div>

            <div className="itemScheduleActionsAdminSchedule" id="view_rent">
              <button
                id="class_attendance_info_admin_button"
                onClick={this.view_rent_button}
              >
                {" "}
                View Rent Info{" "}
              </button>
            </div>

            <div className="itemScheduleActionsAdminSchedule" id="pay_rent">
              <button
                id="class_attendance_info_admin_button"
                onClick={this.pay_rent_button}
              >
                {" "}
                Pay Rent{" "}
              </button>
            </div>

            <div
              className="itemScheduleActionsAdminSchedule"
              id="class_cancel_once"
            >
              <button
                id="class_attendance_info_admin_button"
                onClick={this.class_cancel_once_button}
              >
                {" "}
                Cancel Once{" "}
              </button>
            </div>

            <div
              className="itemScheduleActionsAdminSchedule"
              id="class_cancel_once_restore"
            >
              <button
                id="class_attendance_info_admin_button"
                onClick={this.class_cancel_once_restore_button}
              >
                {" "}
                Restore{" "}
              </button>
            </div>

            <div
              className="itemScheduleActionsAdminSchedule"
              id="class_reschedule"
            >
              <button
                id="class_attendance_info_admin_button"
                onClick={this.reschedule_class}
              >
                {" "}
                Reschedule{" "}
              </button>
            </div>

            <div
              className="itemScheduleActionsAdminSchedule"
              id="class_deactivate"
            >
              <button
                id="class_attendance_info_admin_button"
                onClick={this.class_deactivate_button}
              >
                {" "}
                Deactivate{" "}
              </button>
            </div>

            <div className="itemScheduleActionsAdminSchedule" id="class_remove">
              <ModalExampleCloseIcon
                class_remove_button={this.class_remove_button.bind(this)}
              />
            </div>
          </div>
        </div>
        {/*Start of filter based on class Type buttons*/}
        <div id="class_type_buttons">
          <Divider horizontal>
            <h4>
              <Icon name="hide" />
              <Icon name="unhide" />
              Actions
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button1"
                    size="mini"
                    onClick={this.groups_button}
                  >
                    {" "}
                    {this.state.group_classes} Group Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button2"
                    size="mini"
                    onClick={this.fixed_privates_button}
                  >
                    {" "}
                    {this.state.fixed_privates} Fixed Private Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button3"
                    size="mini"
                    onClick={this.privates_button}
                  >
                    {" "}
                    {this.state.privates} Private Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button4"
                    size="mini"
                    onClick={this.workshops_button}
                  >
                    {" "}
                    {this.state.workshops} Workshops{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button5"
                    size="mini"
                    onClick={this.fixed_rent_button}
                  >
                    {" "}
                    {this.state.fixed_rent} Fixed Rents{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button6"
                    size="mini"
                    onClick={this.rent_button}
                  >
                    {" "}
                    {this.state.rent} Rents{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button7"
                    size="mini"
                    onClick={this.fixed_team_reservation_button}
                  >
                    {" "}
                    {this.state.fixed_team_reservation} Fixed Team Reservations{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button8"
                    size="mini"
                    onClick={this.team_reservation_button}
                  >
                    {" "}
                    {this.state.team_reservation} Team Reservations{" "}
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/*End of filter based on class Type buttons*/}

        {/*Pay Rent*/}
        <div id="rent_info_pay">
          <Divider horizontal>
            <h4>
              <Icon name="credit card" />
              Pay Room Rental
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Renter Name</Table.Cell>
                <Table.Cell>{this.state.rentNamePay}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Rent Price</Table.Cell>
                <Table.Cell>{this.state.rentValuePay}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Discount</Table.Cell>
                <Table.Cell>
                  {this.state.rentDiscountPay * 100 + " %"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Rent Price (After Discount)</Table.Cell>
                <Table.Cell>
                  {this.state.rentValueAfterDiscountPay + " EGP"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Already Paid Amount</Table.Cell>
                <Table.Cell>{this.state.rentPaidPay + " EGP"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Remaining Amount</Table.Cell>
                <Table.Cell>{this.state.rentRemainingPay + " EGP"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Amount</Table.Cell>
                <Table.Cell>
                  <input
                    id="amount-pay"
                    type="number"
                    placeholder="Enter amount"
                    onChange={this.handleAmountRentPayment}
                  />
                  {" EGP"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Payment Method</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    text={this.state.rentNewMethod}
                    selection
                    options={this.state.paymentMethodsAvailable}
                    onChange={this.handleRentNewMethod}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div id="submit_pay_div">
            <Button
              id="submit_pay_rent"
              floated="right"
              size="mini"
              color="red"
              onClick={this.submitPayRent}
            >
              {" "}
              Submit Payment{" "}
            </Button>
          </div>
        </div>

        {/*view Rent*/}
        <div id="rent_info_view">
          <Divider horizontal>
            <h4>
              <Icon name="credit card" />
              View Room Rental Info
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Renter's Name</Table.Cell>
                <Table.Cell>{this.state.rentNamePay}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Rent Price</Table.Cell>
                <Table.Cell>{this.state.rentValuePay}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Discount</Table.Cell>
                <Table.Cell>
                  {this.state.rentDiscountPay * 100 + " %"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Rent Price (After Discount)</Table.Cell>
                <Table.Cell>
                  {this.state.rentValueAfterDiscountPay + " EGP"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Already Paid Amount</Table.Cell>
                <Table.Cell>{this.state.rentPaidPay + " EGP"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Remaining Amount</Table.Cell>
                <Table.Cell>{this.state.rentRemainingPay + " EGP"}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div id="submit_hide_rent_div">
            <Button
              id="submit_hide_rent"
              floated="right"
              size="mini"
              color="red"
              onClick={this.submitHideRent}
            >
              {" "}
              Hide{" "}
            </Button>
          </div>
        </div>

        {/*Start of All Schedule Actions*/}
        <div id="schedule_actions">
          <Divider horizontal>
            <h4>
              <Icon name="calendar" />
              Actions
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Select Room</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select Room"
                    selection
                    options={this.state.rooms_choice}
                    onChange={this.chooseRoom}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell width={4}>Add New Class</Table.Cell>
                <Table.Cell>
                  <Button
                    id="newclass_button"
                    size="mini"
                    color="red"
                    onClick={this.new_class}
                  >
                    {" "}
                    Add New Class{" "}
                  </Button>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell width={4}>Rent / Reserve Room</Table.Cell>
                <Table.Cell>
                  <Button
                    id="rent_reserve_button"
                    size="mini"
                    color="red"
                    onClick={this.rent_reserve}
                  >
                    {" "}
                    Rent / Reserve Room{" "}
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/*End of All Schedule Actions*/}

        {/*Start of Adding Class Form*/}
        <div id="formDivNewClass">
          <Segment id="add_class" inverted>
            <Form id="formNewClas" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Dance Category</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select dance category"
                    selection
                    options={this.state.dance_categories}
                    onChange={this.handleChangeCategory}
                  />
                </Form.Field>

                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Dance</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select dance"
                    selection
                    options={this.state.dances_options}
                    onChange={this.handleChangeDance}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Class Type</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select class type"
                    selection
                    options={this.state.class_types_available}
                    onChange={this.handleChangeClassType}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Level</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select level of the class"
                    selection
                    options={this.state.levels}
                    onChange={this.handleChangeLevel}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">
                    Duration (minutes)
                  </label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Duration (minutes)"
                    selection
                    options={this.state.durationOptions}
                    onChange={this.handleDuration}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Description</label>
                  <input
                    className="input"
                    placeholder="Description"
                    onChange={this.handleClassName}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field className="fullWidthFormAdminSchedule" id="slot">
                <label className="labelAdminSchedule">
                  Number of Slots per Week
                </label>
                <Dropdown
                  className="Dropdown"
                  placeholder="Select number of slots per week"
                  selection
                  options={this.state.slot_count}
                  onChange={this.slot_count_set}
                />
              </Form.Field>

              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Appointment 1</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy hh:mm a"
                    value={this.state.date1_start}
                    onChange={this.handleChangeDate1Start}
                  />
                </Form.Field>

                <Form.Field className="formHalfWidthAdminSchedule" id="date2">
                  <label className="labelAdminSchedule">Appointment 2</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy hh:mm a"
                    value={this.state.date2_start}
                    onChange={this.handleChangeDate2Start}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Room 1</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select room"
                    selection
                    options={this.state.rooms}
                    onChange={this.chooseRoomNew1}
                  />
                </Form.Field>

                <Form.Field
                  className="formHalfWidthAdminSchedule"
                  id="room_extra"
                >
                  <label className="labelAdminSchedule">Room 2</label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Select room"
                    selection
                    options={this.state.rooms}
                    onChange={this.chooseRoomNew2}
                  />
                </Form.Field>
              </Form.Group>
              <Button
                id="submitAdminScheduleNewClass"
                floated="right"
                type="submit"
                onClick={this.addClass}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>
        {/*End of Adding Class Form*/}

        {/*Start of Reschedule Class Form*/}
        <div id="formDivRescheduleClassEdit">
          <Segment id="edit_class" inverted>
            <Form id="formEditClas" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">
                    Duration (minutes)
                  </label>
                  <Dropdown
                    className="Dropdown"
                    text={this.state.duration + " Minutes"}
                    selection
                    options={this.state.durationOptions}
                    onChange={this.handleDuration}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field
                className="fullWidthFormAdminSchedule"
                id="reschedule_slots1"
              >
                <label className="labelAdminSchedule">
                  Number of Slots per Week
                </label>
                <Dropdown
                  className="Dropdown"
                  text={
                    this.state.slot_count_current == 1
                      ? "Once a week"
                      : "Twice a week"
                  }
                  selection
                  options={this.state.slot_count}
                  onChange={this.slot_count_set}
                />
              </Form.Field>

              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Appointment 1</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy hh:mm a"
                    value={this.state.date1_start}
                    onChange={this.handleChangeDate1Start}
                  />
                </Form.Field>

                <Form.Field
                  className="formHalfWidthAdminSchedule"
                  id="date2_reschedule"
                >
                  <label className="labelAdminSchedule">Appointment 2</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy hh:mm a"
                    value={this.state.date2_start}
                    onChange={this.handleChangeDate2Start}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Room 1</label>
                  <Dropdown
                    className="Dropdown"
                    text={"Room " + this.state.roomNew1}
                    selection
                    options={this.state.rooms}
                    onChange={this.chooseRoomNew1}
                  />
                </Form.Field>

                <Form.Field
                  className="formHalfWidthAdminSchedule"
                  id="room_extra_reschedule"
                >
                  <label className="labelAdminSchedule">Room 2</label>
                  <Dropdown
                    className="Dropdown"
                    text={"Room " + this.state.roomNew2}
                    selection
                    options={this.state.rooms}
                    onChange={this.chooseRoomNew2}
                  />
                </Form.Field>
              </Form.Group>
              <Button
                id="submitAdminScheduleEditClass"
                floated="right"
                type="submit"
                onClick={this.class_reschedule_button}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>
        {/*End of Reschedule Class Form*/}

        {/*Start of Rent / Reserve Form*/}
        <div id="formDivRentReserve">
          <Segment id="rent_reserve" inverted>
            <Form id="formRentReserve" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">
                    Rent / Team Reservation
                  </label>
                  <Dropdown
                    className="Dropdown"
                    placeholder="Rent / Team Reservation"
                    selection
                    options={this.state.rent_reserve_available}
                    onChange={this.handleRentReserve}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthAdminSchedule">
                  <label className="labelAdminSchedule">Name</label>
                  <input
                    className="input"
                    placeholder="Name"
                    onChange={this.handleRentReserveName}
                  />
                </Form.Field>
              </Form.Group>

              <div id="reserve_fields">
                <Form.Group>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">
                      Is Fixed / Onetime Appointments?
                    </label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Fixed / Onetime Appointments"
                      selection
                      options={this.state.appointments_types_available}
                      onChange={this.handleAppointments_types}
                    />
                  </Form.Field>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">
                      Duration (minutes)
                    </label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Duration (minutes)"
                      selection
                      options={this.state.durationOptions}
                      onChange={this.handleRentReserveDuration}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field className="date">
                    <label className="labelAdminSchedule">Room</label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Select room"
                      selection
                      options={this.state.rooms}
                      onChange={this.chooseRoomRentReserve}
                    />
                  </Form.Field>
                  <Form.Field className="date">
                    <label className="labelAdminSchedule">Appointment</label>
                    <DateTimePickerComponent
                      id="StartTime"
                      className="e-field"
                      format="dd/MM/yy hh:mm a"
                      value={this.state.rentReserveDateStart}
                      onChange={this.handleRentReserveDateStart}
                    />
                  </Form.Field>
                </Form.Group>
              </div>
              <div id="rent_fields">
                <Form.Group>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">Room</label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Select room"
                      selection
                      options={this.state.rooms}
                      onChange={this.chooseRoomRentReserve}
                    />
                  </Form.Field>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">
                      Is Fixed / Onetime Appointments?
                    </label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Fixed / Onetime Appointments"
                      selection
                      options={this.state.appointments_types_available}
                      onChange={this.handleAppointments_types}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">
                      Duration (minutes)
                    </label>
                    <Dropdown
                      className="Dropdown"
                      placeholder="Duration (minutes)"
                      selection
                      options={this.state.durationOptions}
                      onChange={this.handleRentReserveDuration}
                    />
                  </Form.Field>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">Discount (%)</label>
                    <input
                      id="discount_reset"
                      type="number"
                      placeholder="Discount (%)"
                      onChange={this.handleRoomRentDiscount}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">Price (EGP)</label>
                    <input
                      type="number"
                      value={
                        (this.state.roomRentPrice *
                          this.state.rentReserveDuration) /
                        60
                      }
                      readOnly
                    />
                  </Form.Field>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">Appointment</label>
                    <DateTimePickerComponent
                      id="StartTime"
                      className="e-field"
                      format="dd/MM/yy hh:mm a"
                      value={this.state.rentReserveDateStart}
                      onChange={this.handleRentReserveDateStart}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field className="form2">
                    <label className="labelAdminSchedule">
                      Price After Discount (EGP)
                    </label>
                    <input
                      type="number"
                      value={
                        (this.state.roomRentPriceAfterDiscount *
                          this.state.rentReserveDuration) /
                        60
                      }
                      readOnly
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <Button
                id="submitAdminScheduleRentReserve"
                floated="right"
                type="submit"
                onClick={this.addRentReserve}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>

        {/*End of Rent / Reserve Form*/}
      </>
    );
  }
}

export default Schedule;
//this.navTo(`/classes/${this.state.classIdAttendance}`)
