import React,{useState} from 'react'
import { TextArea } from 'semantic-ui-react'
import scheduleVertical from '../../media/Images/scheduleVertical.png';
// import scheduleSample from '../../media/Images/ScheduleSample.JPG';
import scheduleSample from '../../media/Images/ScheduleSample.jpeg';
import '../../stylesheets/SchedulePart.css';
import { Link } from 'react-router-dom';

const SchedulePart = () =>{

  const handleSchedule = ()=>{
    document.getElementById('SCHEDULE').scrollIntoView();
  }

  
return (
      <div id='ScheduleBackground' >
        <div className='scheduleS'>
          <img id='scheduleSample' src={scheduleSample}/>
        </div>
        
      </div>
    )
  }
  export default SchedulePart;