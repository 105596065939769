import React from 'react'
import { Slide  } from 'react-slideshow-image';
import '../../stylesheets/promo_part.css';
import 'react-slideshow-image/dist/styles.css';
import { Icon } from 'semantic-ui-react'

import rasha from '../../media/Images/new_photos/Rasha.webp'
import salah from '../../media/Images/new_photos/Salah.webp'
import shady from '../../media/Images/new_photos/Shady.webp'
import mai from '../../media/Images/new_photos/Mai.webp'
import youssef from '../../media/Images/new_photos/Youssef.webp'
import nada from '../../media/Images/new_photos/Nada.webp'

// const slideImages = [
//   'images/slide_2.jpg',
//   'images/slide_3.jpg',
//   'images/slide_4.jpg'
// ];

const PromoPart = () => {

    return (
    <>
      <div id='slide' >
          <Slide  pauseOnHover={true} canSwipe={true} indicators={false} arrows={true} >{/*easing="linear" canSwipe={true} indicators={true} arrows={false}*/}
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={rasha} alt="Site Promo Cannot be displayed"/>
            </div>
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={salah} alt="Site Promo Cannot be displayed"/>
            </div>
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={shady} alt="Site Promo Cannot be displayed"/>
            </div>
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={mai} alt="Site Promo Cannot be displayed"/>
            </div>
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={youssef} alt="Site Promo Cannot be displayed"/>
            </div>
            <div className="each-slide">
              <img className="fullscreen-bg__video" src={nada} alt="Site Promo Cannot be displayed"/>
            </div>
            {/* <div className="each-slide">
              <video className="fullscreen-bg__video" loop autoPlay muted>
                <source className='srcx' src={tarrab_nada} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
            </div>
            <div className="each-slide">
              <video className="fullscreen-bg__video" loop autoPlay muted>
                <source className='srcx' src={shady_mai} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
            </div> */}

          </Slide >
      </div>

    </>
  );
}

export default PromoPart
